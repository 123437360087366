import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import useClickOutside from "../helpers/useClickOutside";
import type { MenuData } from "../rest.interface";
import MenuItem, { filterMenuItemsByParent } from "./menuItem";
import clsx from 'clsx';
import { useWindowScroll } from "@uidotdev/usehooks";

interface Props {
	menu: MenuData,
	pathname: string,
}

const Header = ({menu, pathname, ...otherProps}: Props) => {
	const refHeader = useRef<HTMLHeadingElement>(null);
	const refMobileMenu = useRef<HTMLDivElement>(null);
	const [opened, setOpened] = useState<boolean>(false);
	useClickOutside(refMobileMenu, () => setOpened(false));

	const [{ x, y }] = useWindowScroll();
	const [isSticky, setIsSticky] = useState<boolean>(false);

	if (isSticky === false && y > 100) {
		setIsSticky(true);
	}
	if (isSticky === true && y == 0) {
		setIsSticky(false);
	}

	useEffect(() => {
		setOpened(false);
	}, [pathname]);

	let logo = <h2 className={clsx("logo my-4 md:my-5 transition-all", isSticky && '')}>Celia Montague</h2>;
	if (pathname !== '/') {
		logo = <a href="/" className="inline-block">{logo}</a>;
	} else {
		logo = <span className="inline-block">{logo}</span>;
	}

	const rootMenuItems = filterMenuItemsByParent(menu, null);

	return (<>
		{/*<Dump {...menu}/>*/}
		{/*<div className="fixed top-0 left-0 z-50">*/}
		{/*	<Dump value={y}/>*/}
		{/*</div>*/}

		<div
			id="headerWrapper"
			className={clsx("-top-[1px] backdrop-blur-md sticky z-10 transition-all ease-mine duration-500 font-lato", isSticky ? "shadow bg-secondary-300/75" : "bg-white/75")}
			{...otherProps}
			ref={refHeader}
		>
			<header className={clsx("")}>
				<div className={clsx("flex sm:flex-wrap items-center gap-x-8 justify-between lg:justify-center px-8 lg:px-0", isSticky ? "" : 'pt-4 lg:pt-8 max-lg:pb-4')}>
					{logo}

					<a href="#mobileMenu" className={clsx("iconHamburger block lg:hidden shrink-0 my-2", opened && "iconHamburgerOpened")} aria-label="Menu" title="Menu"
					   onClick={() => setOpened(!opened)}>
						<span/>
						<span/>
						<span/>
					</a>

					<div className={clsx("hidden lg:flex items-center justify-between w-full px-8", !isSticky ? "mt-6 bg-secondary-300" : "order-2 self-center")}>
						<menu className="mainMenu uppercase">
							{rootMenuItems && rootMenuItems.map((item, index) => {
								return <MenuItem menu={menu} item={item} key={'item' + index} pathname={pathname} className={clsx(isSticky && 'isSticky')}/>;
							})}
						</menu>
					</div>
				</div>
			</header>
		</div>

		<div className={clsx(
			"fixed top-0 right-0 z-40 h-full w-full transition-all duration-300",
			opened ? 'bg-white/30 visible backdrop-blur-sm' : 'backdrop-blur-none bg-white/0 invisible'
		)}/>

		<div id="mobileMenu" className={clsx(
			"fixed top-0 right-0 z-50 h-full w-full overflow-y-auto overscroll-y-none transition duration-500",
			opened ? 'translate-x-0' : 'translate-x-full'
		)}>
			<div className="float-right relative min-h-full w-[80%] bg-white p-8 sm:p-12 shadow-xl" ref={refMobileMenu}>
				<a href="#" className="iconOpenClose iconOpened absolute top-2 right-6" onClick={() => setOpened(false)} title="X"></a>
				<menu className="mobileMenu font-medium">
					{rootMenuItems && rootMenuItems.map((item, index) => {
						return <MenuItem menu={menu} item={item} key={'item' + index} pathname={pathname}/>;
					})}
				</menu>
			</div>
		</div>
	</>);
}

export default Header;
